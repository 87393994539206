import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AuthGuardService } from "./services/auth/auth-guard.service";
import { HomeComponent } from "./home/home.component";
import { ProductSearchComponent } from "./product/product-search/product-search.component";
import { ProductDetailsComponent } from "./product/product-details/product-details.component";
import { FaqComponent } from "./faq/faq.component";
import { InstructionalManualComponent } from "./instructional-manual/instructional-manual.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { RegisterFormComponent } from "./register-form/register-form.component";

const routes: Routes = [
  {
    path: "unrestricted-url-access",
    component: HomeComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "register-form",
    component: RegisterFormComponent,
  },
  {
    path: "product-search",
    component: ProductSearchComponent,
  },
  {
    path: "product-details",
    component: ProductDetailsComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "instructional-manual",
    component: InstructionalManualComponent,
  },
  {
    path: "user-profile",
    component: UserProfileComponent,
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  
  { path: "", redirectTo: "login", pathMatch: "full" },

  // otherwise redirect to home
  { path: "**", component: LoginComponent },

];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],

})
export class AppRoutingModule {}
