import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ProductSearchComponent } from './product/product-search/product-search.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatBadgeModule} from '@angular/material/badge';

import { FaqComponent } from './faq/faq.component';
import { InstructionalManualComponent } from './instructional-manual/instructional-manual.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { UserRegistrationDetailsComponent } from './user-registration-details/user-registration-details.component';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';

import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

import { DialogModule } from '@syncfusion/ej2-angular-popups';

import { GridAllModule } from '@syncfusion/ej2-angular-grids';
 import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import {MatMenuModule} from '@angular/material/menu';
import { HighlightPipe } from './pipe/highlight.pipe';
import { AddNoteComponent } from './add-note/add-note.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MatNativeDateModule } from '@angular/material/core';
import { RegisterFormComponent } from './register-form/register-form.component';
import { SharedModule } from './shared/shared.module';

import { AuthInterceptor } from './shared/interceptors/auth-interceptors';
import { NumberFormatterPipe } from './pipe/number-formatter.pipe';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProductSearchComponent,
    ProductDetailsComponent,
    FaqComponent,
    InstructionalManualComponent,
    HeaderComponent,
    FooterComponent,
    UserRegistrationDetailsComponent,
    HighlightPipe,
    NumberFormatterPipe,
    AddNoteComponent,
    UserProfileComponent,
    RegisterFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatGridListModule,
    MatSelectModule,
    MaterialFileInputModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatMenuModule,
    HttpClientModule,
    CheckBoxModule,
    DropDownListAllModule,
    ToolbarModule,
    GridAllModule,
    NumericTextBoxAllModule,
    DialogModule,
    Ng2SearchPipeModule,
    SharedModule,
    MsalModule.forRoot({
      auth: {
      //   clientId: '0e307113-75d1-43dd-acd8-bc5ec55a0af4', // This is your client ID
      //  authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
      // redirectUri: 'https://dev-traceability.azurewebsites.net/auth'// This is your redirect URI
      //  clientId: '0e307113-75d1-43dd-acd8-bc5ec55a0af4', // This is your client ID
      //  authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
      // redirectUri: 'https://test-traceability.azurewebsites.net/auth'// This is your redirect URI
       clientId: '73866854-7051-487d-bcdd-9398fd283385', // This is your client ID
        authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
        redirectUri: 'https://circorsmart.azurewebsites.net/auth',// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      },
    }, {
      popUp: !isIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources: [],
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ],
      extraQueryParameters: {}
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
