<body>
    <div class="lgn_hdr">
        <img src="../../assets/logo.svg"/>
    </div>

    <div>
        <div class="lgn_bnr">
            <div class="lgn_csmart_bnr">
                <span class="csmart_txt">CIRCORSmart</span>
            </div>
           
            <div class="rgt_lgn_bnr">
                <div class="rgt_lbnr_top">
                    <div class=" mprp">
                        <span class="login_txt">LOGIN</span>
                    </div>
                    <div>
                    <span *ngIf="message" class="alert alertDanger">
                        <span>Your login failed, due to one of these reasons. User not - Found / Approved / Active</span>
                    </span>
                    <span *ngIf="emptyErrMessage" class="alert alertDanger">
                        Email address is required
                    </span>
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                        <div>
                            <mat-form-field class="inputWidth">
                            <mat-label>Email ID</mat-label>
                            <input formControlName="userName" matInput placeholder="Please enter email as a user id">
                            </mat-form-field>
                        </div>
                        <div class="d-flex justify-content-cen mb20 mr27"><button mat-button class="loginButton" type="submit">LOGIN</button></div>
                    </form>
                    </div>
                </div>

                  <div class="reg_hldr">
                    <div> <span class="reg_txt">Don't have an account? <span class="reg_redtxt">Register Now!</span></span></div>
                    <button class="regButton" type="button" (click)="goToRegister()">REGISTER</button>
                  </div>
            </div>
        </div>
       
        <div class="desc_txt">

            <div class="wd50 nflex">
                <h1>GET SMARTAPP FOR MOBILE USERS</h1>
                <p>Discover the difference having immediate access to critical information and product tracking can have on your operation and its uptime.</p>
                <div class="qr_hldr">
                 <span class="spn_apple">
                    <a href="https://apps.apple.com/us/app/circorsmart/id1540756845" target="_blank"> 
                    <img src="../../assets/Apple-badge-and-QR-code.png"/></a>
                </span>
                <span class="spn_goo">
                    <a href="https://play.google.com/store/apps/details?id=com.circor.pipelineapp" target="_blank">
                        <img src="../../assets/Google-Play-badge-and-QR-code.png"/></a>
                </span>
            </div>
        </div>

        
            <div class="wd66">
                <img src="../../assets/circor_smart_app_logo.png" class="cir_sm_logo"/>
                <h2>The app designed to make your job easier. 
                </h2>
                <p>CIRCOR is known for its difference-making solutions and the CIRCORSmart app is no exception. CIRCORSmart’s constant access to critical information paired with the reliability of CIRCOR’s products keep downtime to an absolute minimum.
                </p>
        </div>

        </div>

        <div class="cir_ban">
            <img src="../../assets/circorsmart-ban.jpg"/>
        </div>
       
    </div>
</body>
  


