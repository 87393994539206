<!-- <div class="header" >
    <img src="../../../assets/Circor_logo_login.png" alt="Circor"/> <div class="pipe">&nbsp; &nbsp;|</div>
    <div class="admintxt" >ADMIN</div>
</div> -->

        <div class="header" *ngIf="!isHeader">
            <div class="logo-left" [routerLink]="['product-search']">
                <img src="../../assets/logo.svg" alt="Circor"/>
                <span *ngIf="role=== 'Admin'">Smart admin</span>
                <span *ngIf="role === 'User' || role === 'Internal' ">Smart user</span>
            </div>
            
            <div class="d-flex mr20">

                <!-- <div class="notificationheader">
                    <button class="mat-btn ttu" mat-button [matMenuTriggerFor]="pushNotificationMenu">
                         <span class="notification">
                            <span class="ntfn_bg">
                                <span class="ntfn_bg_txt">5</span>
                            </span>
                         </span>
                    </button>
                    <mat-menu #pushNotificationMenu="matMenu" xPosition="after" class="pushnot">
                        <div class="dot_border">
                            <a>
                                <span class="d-flex">
                                    <img src="https://img.onesignal.com/tmp/18e27a83-d5e3-45f6-aa7a-93c0eeb86428/c1QLHMyRXuDCeBltcezg_Freilauf%201.jpg" class="mr15"/>
                                    <span class="d-flex-fdc">
                                        <p>Push Notification 1</p>
                                        <span >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus odio vel massa volutpat</span>
                                    </span>
                                </span>
                            </a>
                        </div>

                        <div class="dot_border">
                            <a>
                                <span class="d-flex">
                                    <img src="https://media.onesignal.com/automated_push_templates/feature_announcement_template.png" class="mr15"/>
                                    <span class="d-flex-fdc">
                                        <p>New Exciting feature</p>
                                        <span >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus odio vel massa volutpat</span>
                                    </span>
                                </span>
                            </a>
                        </div>

                        <div class="dot_border">
                            <a>
                                <span class="d-flex">
                                    <img src="https://img.onesignal.com/tmp/18e27a83-d5e3-45f6-aa7a-93c0eeb86428/c1QLHMyRXuDCeBltcezg_Freilauf%201.jpg" class="mr15"/>
                                    <span class="d-flex-fdc">
                                        <p>Test Workshop 1</p>
                                        <span >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus odio vel massa volutpat</span>
                                    </span>
                                </span>
                             </a>
                        </div>

                        <div class="dot_border">
                            <a>
                                <span class="d-flex">
                                    <img src="https://media.onesignal.com/automated_push_templates/feature_announcement_template.png" class="mr15"/>
                                    <span class="d-flex-fdc">
                                        <p>New Exciting feature</p>
                                        <span >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus odio vel massa volutpat</span>
                                    </span>
                                </span>
                            </a>
                        </div>
                        
                        <div class="dot_border">
                        <a>
                            <span class="d-flex">
                                <img src="https://img.onesignal.com/tmp/18e27a83-d5e3-45f6-aa7a-93c0eeb86428/c1QLHMyRXuDCeBltcezg_Freilauf%201.jpg" class="mr15"/>
                                <span class="d-flex-fdc">
                                    <p>Test Workshop</p>
                                    <span >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tempus odio vel massa volutpat</span>
                                </span>
                            </span>
                        </a>
                        </div>

                      
                    </mat-menu>
                 </div> -->

            <div class="circular-square"> <img src="../../assets/user-icon.jpg" /> </div>
            <button class="mat-btn ttu" mat-button [matMenuTriggerFor]="afterMenu">{{ firstName ? firstName + ' ' + lastName : name}}
            <span class="arrow down" *ngIf="downArrow"></span></button>
            <mat-menu #afterMenu="matMenu" xPosition="after">
                <p>{{uniqueName}}</p>
                <p *ngIf="role=== 'Admin'">(Admin)</p>
                <p *ngIf="role=== 'User'">(Guest User)</p>
                <button mat-menu-item [routerLink]="['admin']" *ngIf="role === 'Admin'"><img src="assets/management.png" width="25" class="mr5" />User
                    Management</button>
                <button mat-menu-item (click)="openDelAcctPopUp()"><img src="assets/delete-acct.png" width="25" class="mr5" />Delete Account</button>
                <button mat-menu-item (click)="logout()"><img src="assets/logout.png" width="21" class="mr6" />Logout</button>
            </mat-menu>
            </div>
        </div>


        <div class="overlay" *ngIf="delAcct">
            <div class="overlay_hldr">
            <span class="uad_txt"><span>DELETE ACCOUNT</span>  <span class="close_icon" (click)="delAcct = !delAcct;">Close X</span></span>
            
            <span class="innerbodyTxt">Please note you won't be able to retrieve any details you've shared on CIRCOR Smartapp when you delete your account. Your Notes, Quotes/Orders and all of your messages will also be deleted.</span>
           
            <span class="innerbodyTxt">After clicking “Yes” button, your account will be deleted from the CIRCOR Smart app within a day, post review and acceptance by Smart App Admin team. </span>

            <span class="innerbodyTxt">Please click “Yes” to proceed with the account deletion.</span>
            

            <span class="grid-btn">
            <button class="btn" (click)="delUserAcct()">Yes</button>
            <button class="btn btn_no" (click)="delAcct = !delAcct;">No</button></span>
        
        </div>
        </div>