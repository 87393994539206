import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { UserDetails} from '../models/userDetails'
import{ environment} from '../../environments/environment'
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Router, NavigationExtras } from '@angular/router';
import { map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FetchUserDetailsService {
  accessToken: any;
  result: any;
  headers = new HttpHeaders({'X-Skip-Auth':''});
  constructor(private http: HttpClient,private authService: MsalService,private broadcastService: BroadcastService) { 
  }

  fetchBrandDetails(options?: any): Observable<any>{
    const withAuth = options?.withAuth;
    return this.http.get<any>(`${environment.apiUrl}productLine/brands`,{headers:withAuth ? null : this.headers});
  }

  fetchUserDetails():Observable<UserDetails[]>{ 
     return this.http.get<UserDetails[]>(`${environment.apiUrl}register/getAllOperator`);
 }

 getStatusCount():Observable<any>{
     return this.http.get<any>(`${environment.apiUrl}register/getStatusCount`)
 }

 getStatusApproved(userData:any):Observable<any>{
    return this.http.put<any>(`${environment.apiUrl}user-status`, userData);
 }
  
 getStatusWithoutMail(userData:any):Observable<any>{
  return this.http.put<any>(`${environment.apiUrl}user-status-withoutmail`, userData);
}

//  getStatusUA(userData:any):Observable<any>{
//   return this.http.put<any>(`${environment.apiUrl}register/updateApprovelStatus`, userData);
//  }
  
 fetchOperatorByStatus(status):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}register/fetchOperatorByStatus`,status)
 }
  addUserOnBehalf(userData: UserDetails, options?: any): Observable<any>{
    const withAuth = options?.withAuth;
    return this.http.post<any>(`${environment.apiUrl}register/addUserOnBehalf`, userData, { headers: withAuth ? null : this.headers });
}

updateUserOnBehalf(userData:UserDetails[]):Observable<any>{
  return this.http.post<any>(`${environment.apiUrl}register/updateUserOnBehalf`,userData);
}

deleteUserOnBehalf(userData:UserDetails):Observable<any>{
  return this.http.post<any>(`${environment.apiUrl}register/deleteUserOnBehalf`,userData);
}
fetchUseryEmail(email): Observable<any>{
return this.http.post<any>(`${environment.apiUrl}register/fetchUserByEmail`, email ,{headers:this.headers});
}
  
fetchLoginByEmail(email): Observable<any>{
  return this.http.post<any>(`${environment.apiUrl}/register/fetchloginbyemail`, email, {headers:this.headers});
}  

updateUser(userData: UserDetails): Observable<any>{
  return this.http.put<any>(`${environment.apiUrl}updateUser`, userData);
}

deleteUserAccount(emailId: string): Observable<any> {
  return this.http.put<any>(`${environment.apiUrl}users/${emailId}/`, null);
}

userAcctReactivate(email: string): Observable<any> {
  return this.http.post<any>(`${environment.apiUrl}useraccountreactivate`, email, {headers:this.headers});
}
  
getCountries(): Observable<any> {
  return this.http.get(`${environment.apiUrlCty}/all`).pipe(
    tap((result) => console.log("result", result)),
    map((result) => (this.result = result))
  );
}
  
}
