<!-- <div class="header" ><img src="../../../assets/Circor_logo_login.png" alt="Circor"/><span class="textAlignment">Admin</span></div> -->
<div class="mr30" *ngIf="lastName else uname">
    <span class="welcome-txt">Welcome </span><span class="user-txt"><span>{{lastName}}</span>, {{firstName}}!</span>
</div>
<ng-template #uname>
    <div class="mr30">
    <span class="welcome-txt">Welcome </span><span class="user-txt"><span>{{name}}!</span></span>
    </div>
</ng-template>
<div class="d-flex mr30 justify-content-sb">
    <div class="wd73p">
        <div class="d-flex" *ngIf="role === 'User'">
            <div class="tiles_view">
                <p>LATEST UPDATES</p>
                <div class="d-flex mtp26">
                    <span class="width30p"><img src="../../../assets/spare-parts.png"/></span>
                    <span class="updates_txt">Spare parts feature has been implemented in Product details page, Listed the spare parts details based on the Product serial number and they can raise the Quote or Order for the selected items through respective buttons. </span>
                </div>

            </div>

            <div class="tiles_view1">
                <span class="tiles_view1_left">
                    <span><img src="../../../assets/smartphone.png" class="wd70px"/></span>
                    <span class="tv_redclr_txt">CIRCORSMART</span>
                     <span>Mobile Apps Available</span>
                </span>
                <span class="tiles_view1_right">
                        <span class="d-flex-fdc">
                            <a href="https://apps.apple.com/in/app/circorsmart/id1540756845">
                                <img src="../../../assets/appstoretxt.svg"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.circor.pipelineapp">
                                <img src="../../../assets/gplaytxt.svg"/>
                            </a>
                        </span>
                        
                </span>
            </div>

        </div>
         <div class="dashboard-bg" *ngIf="role === 'Admin'">
            <div class="dashboard-cardhldr">
                <div class="pending cp" (click)="getPendingStatus()">
                    <img src="../../../assets/pending.png" width="31"/>
                    <h5 class="count">{{pending}}</h5>
                    <div class="status">PENDING</div>
                    <div class="progress progressWidth">
                        <div id="pendingBar" class="progress-bar progress-warning" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                            aria-valuemax="100" style="width:0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard-cardhldr">
                <div class="rejected cp" (click)="getRejectedStatus()">
                    <img src="../../../assets/rejected.png" width="31" />
                    <h5 class="count">{{rejected}}</h5>
                    <div class="status">REJECTED</div>
                    <div class="progress progressWidth">
                        <div id="rejectedBar" class="progress-bar progress-danger" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                            aria-valuemax="100" style="width:0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard-cardhldr">
                <div class="approved cp" (click)="getApprovedStatus()">
                    <img src="../../../assets/approved.png" width="31" />
                    <h5 class="count">{{approved}}</h5>
                    <div class="status">APPROVED</div>
                    <div class="progress progressWidth">
                        <div id="approvedBar" class="progress-bar progress-success" role="progressbar" aria-valuenow="60"
                            aria-valuemin="0" aria-valuemax="100" style="width:0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard-cardhldr">
                <div class="revoked cp" (click)="getRevokeStatus()">
                    <img src="../../../assets/blocked.png" width="31" />
                    <h5 class="count">{{revoked}}</h5>
                    <div class="status">REVOKED</div>
                <div class="progress progressWidth">
                    <div id="revokedBar" class="progress-bar progress-gray" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                        aria-valuemax="100" style="width:0">
                    </div>
                </div>
                </div>
            </div>

            <div class="dashboard-cardhldr">
                <div class="blocked cp" (click)="getBlockedStatus()">
                    <img src="../../../assets/blocked.png" width="31" />
                    <h5 class="count">{{blocked}}</h5>
                    <div class="status">BLOCKED</div>
                    <div class="progress progressWidth">
                        <div id="blockedBar" class="progress-bar progress-gray" role="progressbar" aria-valuenow="60"
                            aria-valuemin="0" aria-valuemax="100" style="width:0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard-cardhldr">
                <div class="total cp" (click)="getTotal()">
                    <img src="../../../assets/group-user.png" width="44"/>
                    <h5 class="count">{{total}}</h5>
                    <div class="status">TOTAL USER</div>
                    <div class="progress progressWidth">
                        <div class="progress-bar progress-info" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                            style="width:100%">
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="dashboard-cardhldr">
                <div class="register-behalf reg-button cp" (click)="goToRegisterPage()">
                    <img src="../../../assets/login.png" width="44" />
                    <div class="status">Register<br/> on Behalf</div>
                   
                </div>
            </div> -->

        </div> 


        <div class="card"  *ngIf="role === 'Admin'">
            <div class="card-body">
                <div class="d-flex justify-content-sb">
                <div class="registerBlock searchBoxHeader">User Registration Details</div>
                    <!-- <button class="revokeButton" *ngIf="isSelected" (click)="userRevoked()"><img src="../../../assets/user_revert.png"> REVOKE</button> -->
                    <div>
                  
                        <button *ngIf="!isButton && !isRevoke && isVisible"  class="approveButton" (click)="userApproval()">APPROVE</button>
                        <button *ngIf="!isButton && !isRevoke && !isVisible && !isBlocked" class="approveButton" (click)="userApproval()">APPROVE</button>
                        <button *ngIf="isButton" class="approveButton" (click)="userApproval()">APPROVE</button>
                        <button *ngIf="!isButton && !isRevoke && !isVisible && isBlocked" class="approveButton" (click)="userApproval()">APPROVE</button>
                        <button *ngIf="!isButton && !isRevoke && isVisible && !isBlocked" class="blockedButton" (click)="changeUserStatus('block')">BLOCK</button>
                        <button *ngIf="!isButton && !isRevoke && !isVisible && !isBlocked" class="blockedButton" (click)="changeUserStatus('block')">BLOCK</button>
                        <button *ngIf="!isButton && isRevoke && !isVisible && !isBlocked"  class="blockedButton" (click)="changeUserStatus('block')">BLOCK</button>
                        <button *ngIf="!isButton && !isRevoke && !isVisible && !isBlocked" class="rejectButton" (click)="changeUserStatus('reject')">REJECT</button>
                        <button *ngIf="!isButton && !isRevoke && !isVisible && isBlocked" class="rejectButton" (click)="changeUserStatus('reject')"> REJECT</button>
                        <button *ngIf=" !isButton  && !isRevoke && !isVisible && isBlocked" class="revokeBtn" (click)="changeUserStatus('revoke')">REVOKE</button>
                        <button *ngIf="isRevoke && !isButton" class="revokeBtn" (click)="changeUserStatus('revoke')">REVOKE</button>
                        <button *ngIf="isButton" class="blockedButton" (click)="changeUserStatus('block')">BLOCK</button>
                        <button *ngIf="isButton" class="rejectButton" (click)="changeUserStatus('reject')"> REJECT</button>
                        <button *ngIf="isButton" class="revokeBtn"  (click)="changeUserStatus('revoke')">REVOKE</button>   

                </div>
                </div>
                <!-- //******************************************************************************
                ********************************************************************************* -->
                <!-- <app-user-registration-details></app-user-registration-details> -->
                <div *ngIf="!loading">
                    <ejs-grid #grid [dataSource]='data' [allowResizing]="true" [allowSorting]='true' [allowSelection]='true'
                    load="load" allowFiltering='true' [filterSettings]='filter' allowScrolling='true' [toolbar]='toolbar'
                        (rowSelected)='rowSelected($event)'>
                        <e-columns>
                            <e-column type='checkbox' [allowResizing]="false" [allowFiltering]='false' [allowSorting]='false'
                                width='25'></e-column>
                            <e-column field='firstName' headerText='NAME' width='80'></e-column>
                            <e-column field='email' headerText='EMAIL' width='150' matTooltip="Info about the action">
                            </e-column>
                            <e-column field='location' headerText='LOCATION' width='100'></e-column>
                            <e-column field='jobTitle' headerText='JOB TITLE' width='100'></e-column>
                            <e-column field='department' headerText='DEPARTMENT' width='100'></e-column>
                            <e-column field='orgName' headerText='COMPANY' width='100'></e-column>
                            <e-column field='brand' headerText='BRAND' width='100'></e-column>
                            <e-column field='approvalStatus' headerText='STATUS' width='80'>
                                <ng-template #template let-data>
                                    <div class="myDIV" *ngIf="data.approvalStatus === 'Pending'"> {{data.approvalStatus}}&nbsp;
                                        <!-- <img class="reject" src="../../../assets/user_reject.png"
                                            (click)="userReject(data)" />&nbsp;&nbsp;
                                        <img class="approve" src="../../../assets/user_approve.png"
                                            (click)="userApprove(data)" />&nbsp;&nbsp; -->
                                    </div>
                                    <div class="myDIV" *ngIf="data.approvalStatus === 'Approved'">{{data.approvalStatus}}
                                        <!-- <img class="approve" src="../../../assets/user_revert.png" (click)="userRevoke(data)" /> -->
                                    </div>
                                    <div *ngIf="data.approvalStatus === 'Rejected'"> {{data.approvalStatus}}
                                        <!-- <img class="approve" src="../../../assets/user_approve.png"
                                            (click)="userApprove(data)" /> -->
                                    </div>
                                    <div *ngIf="data.approvalStatus === 'Revoked'"> {{data.approvalStatus}}
                                        <!-- <img class="approve" src="../../../assets/user_approve.png"
                                            (click)="userApprove(data)" /> -->
                                    </div>

                                    <div class="myDIV" *ngIf="data.approvalStatus === 'Blocked'"> {{data.approvalStatus}}
                                        &nbsp;&ensp;
                                        <!-- <img class="approve" src="../../../assets/user_revert.png" (click)="userRevoke(data)" />
                                        <img class="reject" src="../../../assets/user_reject.png" (click)="userReject(data)" />&nbsp;
                                        <img class="approve" src="../../../assets/user_approve.png" (click)="userApprove(data)" />&nbsp; -->
                                    </div>
                                    
                                </ng-template>
                            </e-column>
                            <e-column field='updatedOn' headerText='Date &amp; Time' [valueAccessor]='valueAccess' width='100'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
                <!-- //******************************************************************************
                ********************************************************************************* -->
                <div class="text-center">
                    <div *ngIf="loading" class="spinner-border m-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
        
            </div>
        </div>
    </div>
    <div class="wd24p">
        <div class="card search-view">
            <div class="card-body">
                <p class=" card-title searchBoxHeader">Search and View Product Details</p>
                <form [formGroup]="searchProductForm">
                    <div class="input-group m-b-5 productSearchBlock">
                        <input class="form-control border-right-0 border f-m-l-14" formControlName="productId" id="inputSearch"
                            type="search" placeholder="Product ID / Serial No">
                        <span class="input-group-append">
                            <button class="btn btn-outline-secondary border-left-0 border" id="productSearch" type="button"
                                (click)="wildCardSearch()">
                                <img class="searchImg" src="../../../assets/search.png">
                            </button>
                        </span>
                    </div>
                    <h5 class="searchResultTxt">SEARCH RESULTS</h5>
                    <div class="text-center">
                        <div *ngIf="loader" class="spinner-border m-5" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="col-lg-12 searchResultBox" *ngFor="let products of newProducts"
                        (click)="showProductDetails(products)" value="products.value">
                        {{products}}
                        <img src="assets/note.png" class="img-fluid imgBlock" alt="notes">
                    </div>
                    <!-- <ul class="list-group list-group-flush">
                                <li *ngFor="let products of newProducts" class="list-group-item" (click)="showProductDetails(products)" value="products.value">{{products}}
                                    <img src="assets/note.png" class="img-fluid imgBlock" alt="notes">
                                 </li>
                            </ul> -->
                </form>
            </div>
        </div>

        
    <div class="card uploadCard"  *ngIf="role === 'Admin'">
        <div class="card-body ">
            <p class="searchBoxHeader">Upload Maintenance Details</p>
            <mat-tab-group class="remove-border-bottom">
                <mat-tab label="Tag Register">
                    <mat-form-field class="full-width">
                        <button class="browseButton" matSuffix>Browse</button>
                        <ngx-mat-file-input class="selectFile" placeholder="Select file to upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #tagSelectedFile multiple></ngx-mat-file-input>
                    </mat-form-field><br>
                   
                    <span *ngIf="message==false" class="alert alertDanger">
                        Invalid file format
                    </span><br>

                          <button class="clearButton" (click) = "tagSelectedFile.clear($event)">CLEAR</button> 
                          <button class="uploadButton" (click) = "uploadFiles(tagSelectedFile.value)">UPLOAD</button> 
                      
                </mat-tab>
                <mat-tab label="Product Specific">
                    <mat-form-field class="productBlock">
                        <mat-label>Product Line</mat-label>
                        <mat-select #prodline>
                            <mat-option *ngFor="let productLine of productLines" [value]="productLine">
                                {{ productLine }}
                             </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>Data Category</mat-label>
                        <mat-select #doc [(ngModel)] = "btnChange">
                          <mat-option value="FAQ">FAQ</mat-option>
                        <mat-option value="Spare_Parts">Spare Parts</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="formPadding">
                        <ngx-mat-file-input placeholder="Select file to upload" accept="text/xml" #productSpecific></ngx-mat-file-input>
                            <button matSuffix class="browseButton">Browse</button> 
                            </mat-form-field><br>
                         
                            <span *ngIf="messageProductSpecific==false" class="alert alertDanger">
                                Invalid file format
                            </span><br>
                        <button class="clearButton" (click) = "productSpecific.clear($event)">CLEAR</button> 
                        <button class="uploadButton" (click) = "configureUploadButton(productSpecific.value, prodline.value, doc.value)">UPLOAD</button>
                        <!-- <button class="uploadButton"
                            (click)="configureUploadButton(productSpecific.value,prodline.value,doc.value)">UPLOAD</button> -->
                </mat-tab>
              </mat-tab-group>
        </div>
    </div>
    </div>

    <div class="overlay" *ngIf="postFormSubmit">
        <div *ngIf="dataSharingService.getShowSmallLoader()" id="loader1" class="d-flex justify-content-center align-items-center"><img src="assets/loader1.gif"/></div>
        <div class="overlay_hldr">
        <span class="close_icon1" (click)="closePopup();">Close X</span>
        <form [formGroup]="userStatusForm" class="mtp20">
        <div *ngIf="userstatusPopup">
        <div class="form-grp user_status_popup">
                <label>Email</label>
                <textarea  class="email" cols="1" [value]="userEmailsSelected" disabled></textarea>
        </div>
        <div class="form-grp user_status_popup">
                <label>Message<span class="md_field">*</span></label>
                <textarea formControlName="message" [ngClass]="{ 'is-invalid':  form.message.errors }"></textarea>
                <div class="invalid-feedback" *ngIf="form.message.invalid && (form.message.dirty || form.message.touched)">Message is required</div>
        </div>
    </div>
    <div *ngIf="subscribeMsg" class="subscribe_msg">
        Access has been {{currentUserStatus}}ed for selected users
    </div>
    <span class="grid-btn">
        <span *ngIf="userstatusPopup"> <button class="btn ttu" (click)="setUserStatus()" [disabled]="!userStatusForm.valid">{{currentUserStatus}}</button>
        <button class="btn ttu" (click)="setUserStatusWithoutMail()">{{currentUserStatus}} without Mail</button>
        </span>
       
        <button *ngIf="subscribeMsg" class="btn ttu" (click)="closePopup()">Close</button>
    </span>
      </form>
    </div>
    </div>

</div>
  


       