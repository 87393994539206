import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';

import { Router, NavigationExtras } from "@angular/router";
import { ProductService } from "src/app/services/product.service";
import { ProductDetails } from "../../models/productDetails";
import { UserDetails, UserStatusInfo } from "../../models/userDetails";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { ChangeEventArgs } from "@syncfusion/ej2-dropdowns";
import {
  GridComponent,
  RowSelectEventArgs,
  FilterType,
  QueryCellInfoEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { FetchUserDetailsService } from "src/app/services/fetch-user-details.service";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { FileUploadService } from "src/app/services/file-upload.service";
import jwt_decode from "jwt-decode";
import { Internationalization } from  '@syncfusion/ej2-base'; 
// import { OneSignal } from "onesignal-ngx";

@Component({
  selector: "app-product-search",
  templateUrl: "./product-search.component.html",
  styleUrls: ["./product-search.component.css"],
})
export class ProductSearchComponent implements OnInit, AfterViewInit {

  userStatusForm = this.formBuilder.group({
    message: ['', Validators.required]
  })

  messageProductSpecific: boolean;
  pending: number;
  approved: number;
  rejected: number;
  revoked: number;
  blocked: number;
  total: number;
  userRows: any;
  row: string;
  isSelected: boolean = false;
  loader: boolean;
  loading: boolean;
  isButton: boolean = false;
  isRevoke: boolean = false;
  isBlocked: boolean = false;
  isVisible: boolean = false;
  isMessage: boolean;
  productDetails: ProductDetails[];
  message: boolean;
  messageFAQ: boolean;
  success: string = "Y";
  searchProductForm: FormGroup;
  productSId: any;
  result: string;
  subscription: any;
  accessToken: any;
  public data: any[];
  updatedOn: any;
  public ddldata: Object[];
  public pageSettings: Object;
  public filter: Object;
  public ddlfields: Object = { text: "type", value: "Id" };
  public productLines: string[];
  public formatoptions: Object;
  public toolbar: Object[];
  @ViewChild("grid") public grid: GridComponent;
  public item: Object = {};
  // selectRow:Object;
  public selectRow: Object[] = [];
  newProducts: any;
  userData: UserDetails[] = [];
  productDetail: any;
  token: any;
  firstName: any;
  lastName: any;
  btnChange: any;
  email: any;
  name: string;
  uniqueName: string;
  role: string;
  postFormSubmit: boolean = false;
  userStatusObject: any;
  subscribeMsg: boolean = false;
  userstatusPopup: boolean = false;
  currentSelectRecords: any[];
  userEmailsSelected: string;
  currentUserStatus: string;
  userOperatorId: any;
  public intl: Internationalization = new Internationalization();
  public dateFormatter: Function = this.intl.getDateFormat({ type: 'datetime', format: "M/d/y hh:mm a"});
  constructor(
    private route: Router,
    private fileUploadService: FileUploadService,
    private productService: ProductService,
    private fetchCount: FetchUserDetailsService,
    public dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    // private os: OneSignal
  ) {
    this.dataSharingService.setHeader(false);
    this.searchProductForm = new FormGroup({
      productId: new FormControl(""),
    });
    this.dataSharingService.fetchUserRole.subscribe((res: string) => {
      this.role = res;
    });
    // this.os.init({
    //   appId: "ac86d4e1-6fc3-48af-b563-03af188db39f",
    // });


  }

  /**
   * Name : Sandhya Misal
   * Functionality :Enter product Id/Serial No and display the Corresponding search data
   * Code Desc :Calling fetchProductDetails backend api by providing user entered product id and fetching the
   * Corresponding search data
   **/
 

   get form() { return this.userStatusForm.controls; }


  wildCardSearch() {
    this.loader = true;
    console.log(this.loader);
    this.productSId = this.searchProductForm.get("productId").value;
    console.log("productSId", this.productSId);
    this.listProductDetails();
    // const itemStr = localStorage.getItem("accessToken");
    // if (!itemStr) {
    //   this.listProductDetails();
    // } else {
    //   const item = JSON.parse(itemStr);
    //   const now = new Date();
    //   if (now.getTime() > item.expiry) {
    //     localStorage.removeItem("accessToken");
    //     this.authService
    //       .acquireTokenPopup(OAuthSettings)
    //       .then((tokenResponse) => {
    //         console.log(tokenResponse.accessToken);
    //         this.setAccessToken(tokenResponse.accessToken);
    //         this.listProductDetails();
    //       });
    //   } else {
    //     this.listProductDetails();
    //   }
    // }
  }

  configureUploadButton(productSpecific, prodline, doc) {
    if (this.btnChange === "FAQ") {
      this.uploadFilesProduct(productSpecific, prodline, doc);
    } else {
      this.fileUploadSpareParts(productSpecific, prodline, doc);
    }
  }

  listProductDetails() {
    this.productService
      .listProductDetails(this.productSId)
      .subscribe((data) => {
        console.log("data", data);
        if (data) {
          this.loader = false;
        }
        this.productDetails = data;
        this.newProducts = [];
        console.log(this.productDetails);
        for (var obj of this.productDetails)
          this.newProducts.push(obj.serialNumber);
        console.log(this.newProducts);
      }),
      (error) => {
        console.log(error);
      };
  }

  // callFetchProductDetails() {
  //   this.productService.fetchProductDetails(this.productSId).subscribe(data => {
  //     console.log("data", data);
  //     if (data) {
  //       this.loader = false;
  //     }
  //     this.productDetails = data;
  //     this.newProducts = [];
  //     console.log(this.productDetails);
  //     for (var obj of this.productDetails)
  //       this.newProducts.push(obj.serialNumber);
  //     console.log(this.newProducts);
  //   }), error => {
  //     console.log(error);
  //   }
  // }

  /**
   * Name : Sandhya Misal
   * Functionality : Upload files
   * Code Desc : Upload files
   **/
  uploadFiles(file) {
    console.log("file", file);
    let fileExtension = file._files[0].name;
    if (fileExtension.endsWith(".xlsx")) {
      this.message = true;
      console.log(this.message);
      this.productService.isUploadProgress(fileExtension).subscribe(
        (data) => {
          this.fileUploadService.fileUpload(file).subscribe((data) => {
            console.log(this.message);
          });
          this.snackBar.open(
            "File upload is in process. You will receive  email notification on the status",
            "",
            {
              duration: 3000,
            }
          );
        },
        (err) => {
          this.snackBar.open(
            "Existing file is already in process.please upoad file after the notification",
            "",
            {
              duration: 3000,
            }
          );
        }
      );
    } else {
      this.snackBar.open("Invalid file format", "", {
        duration: 3000,
      });
    }
  }

  clearErrorMessage() {
    this.messageProductSpecific = undefined;
    this.message = undefined;
  }

  uploadFilesProduct(file, prodLine: string, datacategory: string) {
    console.log("file", file);
    let fileExtension = file._files[0].name;
    if (fileExtension.endsWith(".xml")) {
      this.fileUploadService
        .fileUploadProduct(file, prodLine, datacategory)
        .subscribe(
          (data) => {
            // if(this.success=='Y'){
            this.snackBar.open(
              "FAQ uploaded successfully. You will receive  email notification on the status",
              "",
              {
                duration: 3000,
                // panelClass: 'green-snackbar'
              }
            );
            console.log(this.messageProductSpecific);
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.snackBar.open("Invalid file format", "", {
        duration: 3000,
        // panelClass: 'green-snackbar'
      });
    }
  }

  fileUploadSpareParts(file, prodLine: string, datacategory: string) {
    console.log("file", file, prodLine, datacategory);
    let fileExtension = file._files[0].name;
    if (fileExtension.endsWith(".csv")) {
      this.fileUploadService
        .fileUploadSpareParts(file, prodLine, datacategory)
        .subscribe(
          (data) => {
            // if(this.success=='Y'){
            this.snackBar.open(
              "Spare parts uploaded successfully",
              "",
              {
                duration: 3000,
                // panelClass: 'green-snackbar'
              }
            );
            console.log(this.messageProductSpecific);
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.snackBar.open("Invalid file format", "", {
        duration: 3000,
        // panelClass: 'green-snackbar'
      });
    }
  }

  goToRegisterPage() {
    this.route.navigate(["/register"]);
  }
  ngAfterViewInit(){
    var pendingBar = document.getElementById("pendingBar");
    var rejectedBar = document.getElementById("rejectedBar");
    var approvedBar = document.getElementById("approvedBar");
    var revokedBar = document.getElementById("revokedBar");
    var blockedBar = document.getElementById("blockedBar");
    this.fetchCount.getStatusCount().subscribe(
      (count) => {
        this.pending = count.Pending;
        this.approved = count.Approved;
        this.rejected = count.Rejected;
        this.blocked = count.Blocked;
        this.revoked = count.Revoked;
        this.total = count.Total;
        pendingBar.style.width = count.PendingPercentage + "%";
        rejectedBar.style.width = count.RejectedPercentage + "%";
        approvedBar.style.width = count.ApprovedPercentage + "%";
        revokedBar.style.width = count.RevokedPercentage + "%";
        blockedBar.style.width = count.BlockedPercentage + "%";
      },
      (err) => {
        console.log(err);
      }
    );

    this.dataSharingService.gridValue.subscribe((data) => {
      if (data == true) {
        this.fetchCount.getStatusCount().subscribe(
          (count) => {
            this.pending = count.Pending;
            this.approved = count.Approved;
            this.rejected = count.Rejected;
            this.revoked = count.Revoked;
            this.blocked = count.Blocked;
            this.total = count.Total;
            pendingBar.style.width = count.PendingPercentage + "%";
            rejectedBar.style.width = count.RejectedPercentage + "%";
            approvedBar.style.width = count.ApprovedPercentage + "%";
            revokedBar.style.width = count.RevokedPercentage + "%";
            blockedBar.style.width = count.BlockedPercentage + "%";
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    console.log("Access Token-->"+localStorage.getItem('msal.idtoken'));
    // this.accessToken = this.getWithExpiry();
    const localStorageAccessToken = localStorage.getItem("accessToken");
    if(localStorageAccessToken){
      let decodedToken = jwt_decode(localStorageAccessToken);
      this.firstName = decodedToken['given_name'];
      this.lastName = decodedToken['family_name'];
      this.name = decodedToken['name'];
      this.email = decodedToken['unique_name'].includes('#') ? decodedToken['unique_name'].split("#").pop() : decodedToken['unique_name'];
      console.log(this.firstName, this.lastName);
      }else{
      this.route.navigate(['/login']);
    }
    
    this.fetchCount.fetchUseryEmail(this.email).subscribe(data => {
      this.userOperatorId = data.operatorId;
    });
 
    this.productService.fetchProductLines().subscribe((data) => {
      this.productLines = data;
    });
    
    this.fetchCount.fetchOperatorByStatus("Pending").subscribe(
      (data) => {
        this.loading = false;
        this.data = data;
      
      },
      (err) => {
        console.log(err);
      }
    );

    this.dataSharingService.gridValue.subscribe((data) => {
      console.log("booleanValue:", data);
      if (data == true) this.grid.refresh();
    });

    this.filter = { type: "CheckBox" };
    this.toolbar = ["Search"];

    this.filter = { type: "CheckBox" };
  }

  public valueAccess = (field: string, data: object, column: object) => { 
    let value = new Date (data[field]); 
        return this.dateFormatter(value);
    }
  
  public onChange(e: ChangeEventArgs): void {
    this.grid.filterSettings.type = <FilterType>e.value;
    this.grid.clearFiltering();
  }

  showProductDetails(serialNumber) {
    console.log("serial no", serialNumber);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: serialNumber,
        uoId: this.userOperatorId
      },
      queryParamsHandling: "merge"
    };
    this.route.navigate(["/product-details"], navigationExtras);
  }

  getPendingStatus() {
    this.fetchCount.fetchOperatorByStatus("Pending").subscribe(
      (data) => {
        console.log(data);
        this.data = data;
        this.isButton = false;
        this.isRevoke = false;
        this.isBlocked = false;
        this.isVisible = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRejectedStatus() {
    this.fetchCount.fetchOperatorByStatus("Rejected").subscribe(
      (data) => {
        console.log(data);
        this.data = data;
        this.isButton = false;
        this.isRevoke = false;
        this.isBlocked = false;
        this.isVisible = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBlockedStatus() {
    this.fetchCount.fetchOperatorByStatus("Blocked").subscribe(
      (data) => {
        console.log(data);
        this.data = data;
        this.isButton = false;
        this.isRevoke = false;
        this.isVisible = false;
        this.isBlocked = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getApprovedStatus() {
    this.fetchCount.fetchOperatorByStatus("Approved").subscribe(
      (data) => {
        console.log(data);
        this.data = data;
        this.isButton = false;
        this.isRevoke = true;
        this.isBlocked = false;
        this.isVisible = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRevokeStatus() {
    this.fetchCount.fetchOperatorByStatus("Revoked").subscribe(
      (data) => {
        console.log(data);
        this.data = data;
        this.isButton = false;
        this.isRevoke = false;
        this.isVisible = true;
        this.isBlocked = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getTotal() {
    this.fetchCount.fetchUserDetails().subscribe(
      (user) => {
        this.data = user;
        this.isButton = true;
        this.isRevoke = false;
        this.isVisible = false;
        this.isBlocked = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  userApproval() {
    const selectedrecords: object = this.grid.getSelectedRecords();
    console.log("records:", selectedrecords);
    if (Object.keys(selectedrecords).length >= 1) {
      this.userRows = [];
      for (let i = 0; i < Object.keys(selectedrecords).length; i++) {
        if (selectedrecords[i].approvalStatus == "Approved") {
          // this.isMessage=true;
          this.snackBar.open(
            "Please select only pending and blocked registrations to approve",
            "",
            {
              duration: 3000,
              // panelClass: 'green-snackbar'
            }
          );
        } else {
          // this.isMessage=false;
          this.userRows.push(selectedrecords[i]);
        }
      }
          let userStatusObject: any = {};
          let userStatusInfo: UserStatusInfo[] = [];
          for (let i = 0; i < this.userRows.length; i++){
            let userStatusData: any = {};
            userStatusData.operatorId = this.userRows[i].operatorId;
            userStatusData.firstName = this.userRows[i].firstName;
            userStatusData.lastName = this.userRows[i].lastName;
            userStatusData.email = this.userRows[i].email;
            userStatusData.brand = this.userRows[i].brand;
            userStatusData.approvalStatus = "Approved";
            userStatusInfo.push(userStatusData);
          }
          userStatusObject.userInfo = userStatusInfo;
          userStatusObject.message = null;
          this.dataSharingService.setLoader(true);
          this.fetchCount.getStatusApproved(userStatusObject).subscribe(
            (data) => {
              console.log("calling approval method", data);
              // this.row = data.Message;
              if (data.status == true) {
                this.dataSharingService.setLoader(false);
                this.snackBar.open(
                  "Registration has been approved for selected rows",
                  "",
                  {
                    duration: 3000,
                    // panelClass: 'green-snackbar'
                  }
                );
                const selectedOperatorIds = this.userRows.map(u => u.operatorId);
                this.data = this.data.filter(d => !selectedOperatorIds.includes(d.operatorId));
                this.grid.refresh();
                this.dataSharingService.setGrid(true);
              }
            },
            (err) => {
              console.log(err);
            }
          );
    } else {
      this.snackBar.open(
        "Please select atleast one record to perform this action",
        "",
        {
          duration: 3000,
          // panelClass: 'green-snackbar'
        }
      );
    }
  }

  changeUserStatus(status: string) {
    this.currentUserStatus = status;
    this.currentSelectRecords = this.grid.getSelectedRecords();
    console.log("inUserStatusAction:", this.currentSelectRecords);
    if (this.currentSelectRecords?.length >= 1) {
      this.userRows = [];
      const currentStatus = this.currentSelectRecords[0].approvalStatus;
      if (!this.currentSelectRecords.every(r => r.approvalStatus === currentStatus) ){
        this.snackBar.open("Please select records of same status", "",
        {
          duration: 3000,
          });
        return;
      }
      for (let i = 0; i < this.currentSelectRecords.length; i++) {
        console.log("Actual sending rows", this.currentSelectRecords[i]);
        
      if (this.currentSelectRecords[i].approvalStatus == "Blocked" && status === "block") {
          this.snackBar.open("Please select pending, approved, rejected registrations to block", "",
            {
              duration: 3000,
            });
        }
        else if (this.currentSelectRecords[i].approvalStatus != "Pending" && this.currentSelectRecords[i].approvalStatus != "Blocked" && status === "reject") {
          this.snackBar.open("Please select only pending and blocked registrations to reject", "",
            {
              duration: 3000,
            });
        } else if (this.currentSelectRecords[i].approvalStatus != "Approved" && this.currentSelectRecords[i].approvalStatus != "Blocked" && status === "revoke") {
          this.snackBar.open("Please select only approved and blocked registrations to revoke", "",
            {
              duration: 3000,
            });
        } else {
          console.log(this.currentSelectRecords[i]);
          this.userRows.push(this.currentSelectRecords[i]);
          this.subscribeMsg = false;
          this.postFormSubmit = true;      
          this.userEmailsSelected = this.userRows.map(u => u.email).join(', ');
          this.userstatusPopup = true
        }
      }
    } else {
      this.snackBar.open("Please select atleast one record to perform this action", "",
      {
        duration: 3000,
      });
    }
  }

  setUserStatus() {
    let userStatusObject: any = {};
    let userStatusInfo: UserStatusInfo[] = [];
    for (let i = 0; i < this.userRows.length; i++){
      let userStatusData: any = {};
      userStatusData.operatorId = this.userRows[i].operatorId;
      userStatusData.firstName = this.userRows[i].firstName;
      userStatusData.lastName = this.userRows[i].lastName;
      userStatusData.email = this.userRows[i].email;
      userStatusData.brand = this.userRows[i].brand;
      userStatusInfo.push(userStatusData);
    }
    userStatusObject.userInfo = userStatusInfo;
    userStatusObject.message = this.userStatusForm.value.message;
    for (let i = 0; i < Object.keys(userStatusObject.userInfo).length; i++) {
      if (this.currentUserStatus === "block") {
        userStatusObject.userInfo[i].approvalStatus = "Blocked";
      } else if (this.currentUserStatus === "reject") {
        userStatusObject.userInfo[i].approvalStatus = "Rejected";
      } else {
        userStatusObject.userInfo[i].approvalStatus = "Revoked";
      }
    }
    this.dataSharingService.setShowSmallLoader(true);
      this.fetchCount.getStatusApproved(userStatusObject).subscribe(
        (data) => {
          // this.row = data.message;
          if (data.status == true) {
            this.dataSharingService.setShowSmallLoader(false);
            this.userstatusPopup = false;
            this.postFormSubmit = true;
            this.subscribeMsg = true;
            const selectedOperatorIds = this.userRows.map(u => u.operatorId);
            this.data = this.data.filter(d => !selectedOperatorIds.includes(d.operatorId));
            this.grid.refresh();
            this.dataSharingService.setGrid(true);
          }
          this.userRows = [];
          userStatusObject.message = '';
        },
        (err) => {
          console.log(err);
        }
      );
  }
  

  setUserStatusWithoutMail() {
    let userStatusObject: any = {};
    let userStatusInfo: UserStatusInfo[] = [];
    for (let i = 0; i < this.userRows.length; i++){
      let userStatusData: any = {};
      userStatusData.operatorId = this.userRows[i].operatorId;
      userStatusData.firstName = this.userRows[i].firstName;
      userStatusData.lastName = this.userRows[i].lastName;
      userStatusData.email = this.userRows[i].email;
      userStatusData.brand = this.userRows[i].brand;
      userStatusInfo.push(userStatusData);
    }
    userStatusObject.userInfo = userStatusInfo;
    userStatusObject.message = this.userStatusForm.value.message;
    for (let i = 0; i < Object.keys(userStatusObject.userInfo).length; i++) {
      if (this.currentUserStatus === "block") {
        userStatusObject.userInfo[i].approvalStatus = "Blocked";
      } else if (this.currentUserStatus === "reject") {
        userStatusObject.userInfo[i].approvalStatus = "Rejected";
      } else {
        userStatusObject.userInfo[i].approvalStatus = "Revoked";
      }
    }
    this.dataSharingService.setShowSmallLoader(true);
      this.fetchCount.getStatusWithoutMail(userStatusObject).subscribe(
        (data) => {
          // this.row = data.message;
          if (data.status == true) {
            this.dataSharingService.setShowSmallLoader(false);
            this.userstatusPopup = false;
            this.postFormSubmit = true;
            this.subscribeMsg = true;
            const selectedOperatorIds = this.userRows.map(u => u.operatorId);
            this.data = this.data.filter(d => !selectedOperatorIds.includes(d.operatorId));
            this.grid.refresh();
            this.dataSharingService.setGrid(true);
          }
          this.userRows = [];
          userStatusObject.message = '';
        },
        (err) => {
          console.log(err);
        }
      );
  }

  closePopup() {
    this.postFormSubmit = !this.postFormSubmit;
    this.userStatusForm.reset();
  }

  rowSelected(args: RowSelectEventArgs) {
    const selectedrecords: object = this.grid.getSelectedRecords();
    console.log("selected Records in row selected method:", selectedrecords);
  }
}
